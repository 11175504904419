import React from 'react'
import Seo from "../../components/seo/index";
import Layout from "./layout/index";
import POCLocations from './components/poclocations'

const PocLocation = () => {
  return (
    <div>
    <Seo title="Green Heartfulness Run 2024" />
    <Layout isSticky>
      <POCLocations />
    </Layout>
  </div>
  )
}

export default PocLocation;