import { ax, raceURL } from "./index";

export default class KanhaRunService {
  // Project Sec
  login(payLoad) {
    return ax.post("login", payLoad);
  }
  otpverification(payLoad) {
    return ax.post("otpverification", payLoad);
  }
  getracecategory(stateId, cityId) {
    return ax.get(`getracecategory?state_id=${stateId}&city_id=${cityId}`);
  }
  addparticipant(payLoad) {
    return ax.post("addparticipant", payLoad);
  }
  getorderlist() {
    return ax.get("getorderlist");
  }

  getcountrylist() {
    return ax.get("getcountrylist");
  }
  retrypayment(payLoad) {
    return ax.post("retrypayment", payLoad);
  }
  transactionupdate(payLoad) {
    return ax.post("transactionupdate", payLoad);
  }
  raceList() {
    return raceURL.get();
  }
  bibSearch(bibId) {
    return ax.get(`bibsearch?bib_id=${bibId}`);
  }
  runTime(bibId) {
    return ax.get(`runtime?bib_id=${bibId}`);
  }

  getDiscountCode() {
    return ax.get('getdefaultdiscount')
  }

  getDiscountedPriceValue( payload) {
    return ax.post('discountcoupon', payload)
  }

  checkValidCompanyCode( company_code) {
    return ax.get(`company/${company_code}`)
  }

  checkCompanyDetails(company_code){
    return ax.get(`companyregistration/${company_code}`)
  }

  postCompanyRegistration(payload, company_code){
    return ax.post(`companyregistration/${company_code}`, payload)
  }

  certificateDownload(participant_id) {
    return ax.get(`download/${participant_id}`)
  }

  getSearchResult(payload) {
    return ax.post('getparticipantsresult', payload)
  }
}
